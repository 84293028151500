html {
  height: 100%;
}

body {
  height: 100%;
  font-family: Helvetica, Arial, 'Hiragino Kaku Gothic Pro', 游ゴシック,
    'Yu Gothic', YuGothic, Osaka, メイリオ, Meiryo, 'ＭＳ Ｐゴシック', 'MS P Gothic', 'Microsoft YaHei',
    微软雅黑, SimSun, 'Microsoft JhengHei', 微軟正黑體, PMingLiU, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: #000000;
  color: #ffffff;
  overflow-x: hidden;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
